export default {
  "sidebar": "cHX",
  "body": "cHC",
  "form": "cHk",
  "form-section": "cHJ",
  "form-title": "cHs title2",
  "row": "cHF",
  "payment-details": "cHg",
  "col": "cHD",
  "footer": "cHV",
  "footer-button": "cHL"
};
