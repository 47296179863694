export default {
  "request-expense-report-validation": "cYN",
  "account-selector": "cYv",
  "has-error": "cYo",
  "actions": "cYn",
  "action-btn": "cYi",
  "action-btn-success": "cYA",
  "warnings": "cYY",
  "top-border": "cYh",
  "account-selector-subtitle": "cYf",
  "action-btn-right": "cYK",
  "funds-disclaimer": "cYG",
  "account-options": "cYr",
  "account-balance": "cYb"
};
