export default {
  "invoice-sidebar": "cah",
  "body": "caf",
  "box": "caK",
  "box-header": "caG",
  "footer": "car",
  "footer-button": "cab",
  "footer-button-tooltip": "cay",
  "reduced-fontsize": "caU",
  "sidebar-box": "caj",
  "border-top": "caW",
  "row": "cau",
  "status-container": "cHS",
  "due-date-container": "cHc",
  "greyed-out": "cHq",
  "struck-through": "cHZ",
  "overdue": "cHR",
  "primary-actions": "cHQ caj",
  "primary-action": "cHe",
  "danger-action": "cHB",
  "button-icon": "cHE",
  "button-label": "cHd body-2",
  "related-credit-note-title": "cHI caption-bold",
  "paid-at-placeholder": "cHt",
  "collapsible-timeline": "cHM",
  "timeline-status-label": "cHP",
  "related-documents": "cHl"
};
