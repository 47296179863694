export default {
  "container": "cOP",
  "main": "cOl",
  "preview": "cOX",
  "form": "cOC",
  "scrollable": "cOk",
  "header": "cOJ title-1",
  "form-sections": "cOs",
  "form-section": "cOF",
  "footer": "cOg",
  "preview-header": "cOD",
  "centered": "cOV",
  "preview-content-wrapper": "cOL",
  "preview-content": "cOT",
  "payment-link-preview": "cOz"
};
