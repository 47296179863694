export default {
  "row": "cAR",
  "empty": "cAQ",
  "active": "cAe",
  "animated": "cAB",
  "fadein-item": "cAE",
  "cell": "cAd body-2",
  "cell-content": "cAI",
  "cell-amount": "cAt",
  "cell-content-amount": "cAM body-1"
};
