export default {
  "selector": "czJ",
  "pension-selector-content": "czs",
  "withholding-tax-selector-content": "czF",
  "withholding-tax-selector-content-row": "czg",
  "power-select-field": "czD",
  "rate-field": "czV",
  "stamp-revenue-field": "czL",
  "dropdown-option": "czT",
  "dropdown-content": "czz"
};
