export default {
  "header": "cvW",
  "header-type": "cvu",
  "close": "coS",
  "close-icon": "coc",
  "header-infos": "coq",
  "title": "coZ body-1",
  "declined": "coR",
  "canceled": "coQ",
  "approved": "coe",
  "pending": "coB",
  "date": "coE body-2",
  "mileage-icon": "cod",
  "distance-subtitle": "coI body-2",
  "link-icon": "cot",
  "container": "coM",
  "picto": "coP",
  "general": "col",
  "amount": "coX",
  "calculation-container": "coC",
  "counterparty": "cok body-2",
  "infobox": "coJ"
};
