export default {
  "row": "cKp",
  "active": "cKx",
  "dot": "cKw",
  "red": "cKN",
  "green": "cKv",
  "yellow": "cKo",
  "status": "cKn",
  "align-right": "cKi",
  "animated": "cKA",
  "fadein-item": "cKY",
  "cell": "cKh body-2",
  "cell-content": "cKf",
  "amount": "cKK body-1",
  "subtitle": "cKG caption",
  "no-padding": "cKr"
};
