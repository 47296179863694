export default {
  "wrapper": "ciH",
  "request-header": "ciO",
  "request": "cim",
  "scheduled": "cip",
  "scheduled-label": "cix",
  "note": "ciw",
  "note-label": "ciN",
  "note-label-danger": "civ",
  "note-content": "cio",
  "status": "cin",
  "transfer-amount": "cii"
};
