export default {
  "attachment-viewer": "cAi",
  "hide-sidebar": "cAA",
  "sidebar": "cAY",
  "header": "cAh",
  "header-top": "cAf",
  "header-icon": "cAK",
  "header-amount": "cAG",
  "infobox": "cAr",
  "vat": "cAb",
  "validation": "cAy"
};
