export default {
  "referral-invite": "cpi",
  "referral": "cpA",
  "illustration": "cpY",
  "steps": "cph",
  "step": "cpf",
  "share-referral": "cpK",
  "choice": "cpG",
  "line": "cpr",
  "email": "cpb",
  "email-input": "cpy",
  "input-field--error": "cpU",
  "error-message": "cpj",
  "external-link-icon": "cpW"
};
