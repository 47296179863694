export default {
  "wrapper": "crz",
  "header": "cra mb-48",
  "form-title": "crH title-2 mb-16",
  "form-subtitle": "crO body-2",
  "form": "crm",
  "section": "crp mb-48",
  "section-title": "crx title-3 mb-16",
  "form-footer": "crw",
  "footer-btn": "crN",
  "transfer-form": "crv"
};
