export default {
  "container": "cml",
  "content": "cmX",
  "input-row": "cmC",
  "email-row": "cmk",
  "button-row": "cmJ",
  "btn-edit": "cms",
  "input-column": "cmF",
  "form-btn-row": "cmg",
  "border-bottom": "cmD",
  "avatar-input": "cmV"
};
