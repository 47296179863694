export default {
  "row": "cbh",
  "row-active": "cbf",
  "avatar": "cbK",
  "cell": "cbG",
  "cell-content": "cbr",
  "cell-counterparty": "cbb",
  "counterparty-name": "cby",
  "cell-amount": "cbU",
  "status-avatar": "cbj",
  "counterparty": "cbW",
  "creditor-name": "cbu",
  "reference": "cyS",
  "counterparty-details": "cyc",
  "cell-quick-actions": "cyq",
  "quick-actions": "cyZ",
  "account-selector": "cyR"
};
