export default {
  "header": "che",
  "header-type": "chB",
  "close": "chE",
  "close-icon": "chd",
  "header-infos": "chI",
  "title": "cht body-1",
  "declined": "chM",
  "canceled": "chP",
  "approved": "chl",
  "pending": "chX",
  "date": "chC body-2",
  "mileage-icon": "chk",
  "distance-subtitle": "chJ body-2",
  "link-icon": "chs",
  "container": "chF",
  "picto": "chg",
  "general": "chD",
  "amount": "chV",
  "calculation-container": "chL",
  "counterparty": "chT body-2",
  "infobox": "chz"
};
