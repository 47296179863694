export default {
  "container": "czQ",
  "depositAmount": "cze",
  "flexRow": "czB",
  "depositInput": "czE",
  "dropdown": "czd",
  "itemRow": "czI",
  "line": "czt",
  "estimates-container": "czM",
  "estimates": "czP",
  "dt": "czl",
  "dd": "czX"
};
