export default {
  "container": "cHY",
  "form-container": "cHh",
  "content": "cHf",
  "fields": "cHK",
  "input-settings": "cHG",
  "current-year": "cHr",
  "next-invoice-number": "cHb",
  "preview-container": "cHy",
  "actions": "cHU",
  "close-button": "cHj btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cHW",
  "preview-image": "cHu"
};
