export default {
  "header-cell": "cmv",
  "empty": "cmo",
  "header-content": "cmn caption-bold",
  "active": "cmi",
  "align-right": "cmA",
  "col-8": "cmY",
  "col-7": "cmh",
  "col-5": "cmf",
  "col-4": "cmK"
};
