export default {
  "form": "cOa",
  "container": "cOH",
  "section": "cOO",
  "footer": "cOm",
  "attachment": "cOp",
  "attachment-title": "cOx",
  "attachment-uploader-disabled": "cOw",
  "attachment-overlay": "cON",
  "attachment-overlay-hidden": "cOv"
};
