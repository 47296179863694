export default {
  "row": "cyQ",
  "cell": "cye",
  "checkbox": "cyB",
  "icon": "cyE",
  "creditor-item-container": "cyd",
  "actions-item-container": "cyI",
  "due-date": "cyt",
  "amount": "cyM",
  "align-right": "cyP"
};
