export default {
  "content": "cic",
  "balance": "ciq",
  "duration": "ciZ",
  "green-text": "ciR",
  "balance-subtitle": "ciQ",
  "balance-duration": "cie",
  "footer": "ciB",
  "progress": "ciE",
  "progress-bar": "cid",
  "progress-bar-text": "ciI",
  "progress-active": "cit"
};
