export default {
  "cell-content": "ciU",
  "icon": "cij",
  "status_check-m": "ciW",
  "description": "ciu body-1",
  "from": "cAS body-2",
  "from--abort": "cAc",
  "description--abort": "cAq",
  "title": "cAZ body-2"
};
