export default {
  "numbering-container": "cza",
  "flex-row": "czH",
  "automatic-number-label": "czO",
  "icon-tooltip": "czm",
  "fix-width": "czp",
  "dates-container": "czx",
  "with-performance-date": "czw",
  "date-picker": "czN",
  "label": "czv",
  "header-text-field-container": "czo",
  "mt-16": "czn",
  "header-text-field": "czi",
  "header-text-field-close-button": "czA",
  "deposit-toggle-container": "czY"
};
