export default {
  "header-cell": "cKV",
  "header-content": "cKL caption-bold",
  "active": "cKT",
  "empty": "cKz",
  "request-type": "cKa",
  "status": "cKH",
  "note": "cKO",
  "amount": "cKm"
};
