export default {
  "header": "cwP",
  "header-type": "cwl",
  "close": "cwX",
  "close-icon": "cwC",
  "header-infos": "cwk",
  "title": "cwJ body-1",
  "declined": "cws",
  "canceled": "cwF",
  "approved": "cwg",
  "pending": "cwD",
  "date": "cwV body-2",
  "mileage-icon": "cwL",
  "distance-subtitle": "cwT body-2",
  "link-icon": "cwz",
  "container": "cwa",
  "picto": "cwH",
  "general": "cwO",
  "amount": "cwm",
  "calculation-container": "cwp",
  "counterparty": "cwx body-2",
  "infobox": "cww"
};
