export default {
  "row": "cKb",
  "active": "cKy",
  "animated": "cKU",
  "fadein-item": "cKj",
  "cell": "cKW body-2",
  "cell-content": "cKu",
  "subtitle": "cGS caption",
  "no-padding": "cGc",
  "note": "cGq",
  "cell-amount": "cGZ",
  "cell-content-amount": "cGR body-1"
};
