export default {
  "section-container": "cYy",
  "disclaimer-section": "cYU",
  "trip-title": "cYj caption-bold mb-16",
  "google-link-container": "cYW",
  "link-icon": "cYu",
  "trip-details": "chS body-2",
  "trip-details-icon": "chc",
  "trip-details-title": "chq",
  "subtitle": "chZ caption-bold mb-16",
  "disclaimer": "chR",
  "visualizer": "chQ"
};
