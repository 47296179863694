export default {
  "card-details-container": "cAD",
  "title": "cAV caption-bold mb-16",
  "section": "cAL",
  "divider": "cAT",
  "top-divider": "cAz",
  "content": "cAa body-2",
  "description": "cAH",
  "justify-end": "cAO",
  "icon": "cAm ml-8"
};
