export default {
  "credit-note-sidebar": "cLi",
  "body": "cLA",
  "box": "cLY",
  "box-header": "cLh",
  "footer": "cLf",
  "footer-button": "cLK",
  "sidebar-box": "cLG",
  "border-top": "cLr",
  "row": "cLb",
  "related-invoice-title": "cLy caption-bold",
  "einvoice-rejected": "cLU",
  "related-documents": "cLj"
};
