export default {
  "header": "chU",
  "header-type": "chj",
  "close": "chW",
  "close-icon": "chu",
  "header-infos": "cfS",
  "title": "cfc body-1",
  "declined": "cfq",
  "canceled": "cfZ",
  "approved": "cfR",
  "date": "cfQ body-2",
  "container": "cfe",
  "picto": "cfB",
  "request": "cfE",
  "picto-status": "cfd",
  "check": "cfI",
  "stop": "cft",
  "warning": "cfM",
  "general": "cfP",
  "amount": "cfl",
  "counterparty": "cfX body-2",
  "initiator": "cfC",
  "initiator-avatar": "cfk",
  "avatar-image": "cfJ",
  "initiator-id": "cfs",
  "name": "cfF body-2",
  "role": "cfg caption-bold",
  "infobox": "cfD",
  "account-infobox": "cfV cfD",
  "beneficiary-warning": "cfL caption-bold",
  "dates-wrapper": "cfT",
  "dates": "cfz",
  "recurrence": "cfa caption-bold",
  "arrow": "cfH",
  "notify-checkbox": "cfO"
};
