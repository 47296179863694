export default {
  "container": "czh",
  "title-and-trash": "czf",
  "trash": "czK",
  "quantity-amount": "czG",
  "flex-row": "czr",
  "quantity": "czb",
  "quantityInput": "czy",
  "border-left": "czU",
  "dropdown": "czj",
  "item-row": "czW",
  "unit-price": "czu",
  "price-input": "caS",
  "hidden": "cac",
  "d-none": "caq",
  "dropdown-option": "caZ",
  "list-row": "caR",
  "row-title": "caQ",
  "row-price": "cae body-2",
  "row-missing-details-container": "caB",
  "row-missing-details-label": "caE body-2",
  "warning-icon": "cad",
  "row-internal-note": "caI",
  "with-internal-note": "cat",
  "dropdown-content": "caM",
  "select-item": "caP"
};
