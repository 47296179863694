export default {
  "header": "cYq",
  "header-type": "cYZ",
  "close": "cYR",
  "close-icon": "cYQ",
  "header-infos": "cYe",
  "title": "cYB body-1",
  "declined": "cYE",
  "canceled": "cYd",
  "approved": "cYI",
  "pending": "cYt",
  "date": "cYM body-2",
  "container": "cYP",
  "picto": "cYl",
  "picto-status": "cYX",
  "general": "cYC",
  "amount": "cYk",
  "counterparty": "cYJ body-2",
  "initiator": "cYs",
  "initiator-avatar": "cYF",
  "avatar-image": "cYg",
  "initiator-id": "cYD",
  "name": "cYV body-2",
  "role": "cYL caption-bold",
  "infobox": "cYT",
  "account-infobox": "cYz cYT",
  "beneficiary-warning": "cYa caption-bold",
  "dates-wrapper": "cYH",
  "dates": "cYO",
  "recurrence": "cYm caption-bold",
  "arrow": "cYp",
  "notify-checkbox": "cYx"
};
