export default {
  "row": "coW",
  "active": "cou",
  "dot": "cnS",
  "red": "cnc",
  "green": "cnq",
  "yellow": "cnZ",
  "status": "cnR",
  "align-right": "cnQ",
  "animated": "cne",
  "fadein-item": "cnB",
  "cell": "cnE body-2",
  "cell-content": "cnd",
  "amount": "cnI body-2",
  "subtitle": "cnt caption",
  "padding-left": "cnM",
  "padding-right": "cnP",
  "note": "cnl"
};
