export default {
  "section-container": "cwq",
  "disclaimer-section": "cwZ",
  "trip-title": "cwR caption-bold mb-16",
  "google-link-container": "cwQ",
  "link-icon": "cwe",
  "trip-details": "cwB body-2",
  "trip-details-icon": "cwE",
  "trip-details-title": "cwd",
  "subtitle": "cwI caption-bold mb-16",
  "disclaimer": "cwt",
  "visualizer": "cwM"
};
