export default {
  "container": "cmL",
  "share-panel": "cmT",
  "email-margin": "cmz",
  "message-margin": "cma",
  "esignature-container": "cmH",
  "esignature-teaser-title": "cmO",
  "esignature-teaser-badge": "cmm",
  "esignature-teaser-body": "cmp",
  "button-container-footer": "cmx",
  "attachments": "cmw",
  "attachment-file": "cmN"
};
