export default {
  "sections": "crq",
  "actions": "crZ",
  "search-input": "crR",
  "no-upload": "crQ",
  "new-link": "cre body-2",
  "between-links": "crB",
  "action-bar": "crE",
  "empty-state": "crd"
};
