export default {
  "wrapper": "cpu",
  "tabs": "cxS",
  "tabs-nav": "cxc",
  "tabs-panel-wrapper": "cxq",
  "list-head": "cxZ",
  "list": "cxR",
  "footer": "cxQ",
  "reward-amount": "cxe",
  "earned": "cxB",
  "pending": "cxE",
  "empty-state-wrapper": "cxd",
  "empty-state": "cxI",
  "illustration": "cxt",
  "error-wrapper": "cxM",
  "error-illustration": "cxP",
  "error-description": "cxl",
  "spinner": "cxX",
  "total-earned": "cxC"
};
