export default {
  "subtitle": "cnh",
  "green-text": "cnf",
  "balance-subtitle": "cnK",
  "options": "cnG",
  "shadow": "cnr",
  "menu-item": "cnb body-2",
  "close-account": "cny",
  "footer": "cnU",
  "progress": "cnj",
  "progress-bar": "cnW",
  "progress-bar-text": "cnu",
  "progress-active": "ciS"
};
