export default {
  "mileage-validation": "coL",
  "actions": "coT",
  "action-btn": "coz",
  "action-btn-right": "coa",
  "request-mileage-validation": "coH",
  "account-selector": "coO",
  "has-error": "com",
  "funds-disclaimer": "cop",
  "account-options": "cox",
  "account-balance": "cow",
  "warnings": "coN"
};
