export default {
  "mileage-validation": "cwA",
  "actions": "cwY",
  "action-btn": "cwh",
  "action-btn-success": "cwf",
  "action-btn-right": "cwK",
  "request-mileage-validation": "cwG",
  "account-selector": "cwr",
  "has-error": "cwb",
  "funds-disclaimer": "cwy",
  "account-options": "cwU",
  "account-balance": "cwj",
  "warnings": "cwW"
};
