export default {
  "row": "cNR body-1",
  "active": "cNQ",
  "cell": "cNe",
  "empty": "cNB",
  "ellipsis": "cNE",
  "text-secondary": "cNd",
  "transfer-type": "cNI",
  "transfer-type__icon": "cNt",
  "quick-actions": "cNM",
  "amount": "cNP",
  "cell-approve": "cNl",
  "account-select": "cNX",
  "row-compact": "cNC",
  "cell-amount": "cNk",
  "cell-content-receipt": "cNJ body-1",
  "cell-date": "cNs",
  "row-sidebar": "cNF",
  "hidden": "cNg",
  "animated": "cND",
  "fadein-item": "cNV",
  "animated-cell": "cNL",
  "note": "cNT",
  "checkbox": "cNz"
};
