export default {
  "client-form": "cLg",
  "align-start": "cLD",
  "kind": "cLV",
  "name-fields": "cLL",
  "field": "cLT",
  "required-data-disclaimer": "cLz",
  "row": "cLa",
  "child": "cLH",
  "confirm-action": "cLO",
  "delete-action": "cLm",
  "legal-info": "cLp",
  "column-layout": "cLx"
};
