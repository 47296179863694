export default {
  "mileage-validation": "chx",
  "actions": "chw",
  "action-btn": "chN",
  "approve-btn": "chv",
  "request-mileage-validation": "cho",
  "account-selector": "chn",
  "has-error": "chi",
  "funds-disclaimer": "chA",
  "account-options": "chY",
  "account-balance": "chh",
  "warnings": "chf"
};
