export default {
  "request-transfer-validation": "crA",
  "account-selector": "crY",
  "has-error": "crh",
  "actions": "crf",
  "action-btn": "crK",
  "warnings": "crG",
  "top-border": "crr",
  "account-selector-subtitle": "crb",
  "action-btn-right": "cry",
  "funds-disclaimer": "crU",
  "account-options": "crj",
  "account-balance": "crW",
  "request-multi-transfer-validation": "cru",
  "wrapper": "cbS",
  "warning-message-row": "cbc",
  "multi-transfer-align": "cbq",
  "button-approve": "cbZ",
  "button-approve-spinner": "cbR",
  "button-decline": "cbQ"
};
