export default {
  "wrapper": "cpg",
  "tabs": "cpD",
  "tabs-nav": "cpV",
  "tabs-panel": "cpL",
  "tabs-panel-wrapper": "cpT",
  "list-head": "cpz",
  "list": "cpa",
  "footer": "cpH",
  "reward-amount": "cpO",
  "rewarded": "cpm",
  "registered": "cpp",
  "disclaimer": "cpx",
  "empty-state-wrapper": "cpw",
  "empty-state": "cpN",
  "illustration": "cpv",
  "spinner": "cpo",
  "total-earned": "cpn"
};
