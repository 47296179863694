/* import __COLOCATED_TEMPLATE__ from './issue-date-filter.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { getIssueDateFilterOptions } from 'qonto/utils/receivable-invoicing';

interface IssueDateFilterSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class IssueDateFilterComponent extends Component<IssueDateFilterSignature> {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked filterDateValue;

  get filterDateOptions() {
    // @ts-expect-error
    return getIssueDateFilterOptions(this.intl);
  }

  @action updateDateFilter(value: any) {
    this.filterDateValue = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::IssueDateFilter': typeof IssueDateFilterComponent;
  }
}
