export default {
  "iban": "cGi",
  "checkbox": "cGA",
  "toggle-content": "cGY",
  "beneficiary-name": "cGh",
  "category": "cGf",
  "beneficiary-email": "cGK",
  "beneficiary-form": "cGG",
  "beneficiary-email-container": "cGr"
};
