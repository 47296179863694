export default {
  "referral-invite-new": "cpd",
  "referral": "cpI",
  "center": "cpt",
  "illustration": "cpM",
  "share-referral": "cpP",
  "choice": "cpl",
  "line": "cpX",
  "email": "cpC",
  "email-input": "cpk",
  "input-field--error": "cpJ",
  "error-message": "cps",
  "external-link-icon": "cpF"
};
