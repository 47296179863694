export default {
  "header-cell": "cKE",
  "header-content": "cKd caption-bold",
  "active": "cKI",
  "empty": "cKt",
  "align-right": "cKM",
  "request-type": "cKP",
  "requester": "cKl",
  "amount": "cKX"
};
