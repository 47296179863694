export default {
  "invoice-sidebar": "cHT",
  "body": "cHz",
  "box": "cHa",
  "box-header": "cHH",
  "footer": "cHO",
  "footer-button": "cHm",
  "sidebar-box": "cHp",
  "border-top": "cHx",
  "row": "cHw",
  "status-container": "cHN",
  "due-date-container": "cHv",
  "mark-as-btn": "cHo",
  "primary-actions": "cHn cHp",
  "primary-action": "cHi body-1",
  "button-icon": "cHA"
};
