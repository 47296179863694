export default {
  "gap-32": "cTO",
  "fieldset": "cTm",
  "date-picker": "cTp",
  "with-performance-date": "cTx",
  "mt-4": "cTw",
  "clients": "cTN",
  "dropdown-option": "cTv",
  "dropdown-content": "cTo",
  "select-client": "cTn",
  "client-option": "cTi",
  "icon-tooltip": "cTA",
  "payment-details": "cTY",
  "dates-container": "cTh",
  "automatic-number-label": "cTf",
  "label": "cTK",
  "numbering-container": "cTG",
  "fix-width": "cTr",
  "customer-error": "cTb",
  "amount-text": "cTy",
  "header-text-field-container": "cTU",
  "header-text-field": "cTj",
  "header-text-field-close-button": "cTW",
  "mt-16": "cTu"
};
