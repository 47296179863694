export default {
  "qonto-pilot-modal": "cVL",
  "content": "cVT",
  "header": "cVz",
  "transition-state-container": "cVa",
  "lottie": "cVH",
  "prompt-input": "cVO",
  "buttons": "cVm",
  "footer": "cVp caption",
  "divider": "cVx",
  "illustration": "cVw",
  "icon": "cVN",
  "quick-actions-container": "cVv",
  "action": "cVo body-2",
  "action-title": "cVn",
  "disabled": "cVi",
  "action-tag": "cVA caption-bold",
  "hotkey": "cVY body-1",
  "footer-action": "cVh",
  "grow": "cVf",
  "footer-feedback-link": "cVK",
  "buttons-container": "cVG",
  "transfer-details-container": "cVr",
  "error-container": "cVb",
  "option-iban": "cVy",
  "actions": "cVU",
  "title": "cVj",
  "group-title": "cVW caption-bold",
  "beta-badge": "cVu",
  "hotkeys": "cLS",
  "supplier-invoices-dropzone": "cLc",
  "success-icon": "cLq",
  "error-icon": "cLZ",
  "file-items": "cLR",
  "file-item": "cLQ",
  "uploader-file": "cLe",
  "transactions-table": "cLB",
  "transaction": "cLE",
  "prompt-example": "cLd"
};
