export default {
  "row": "cxL",
  "active": "cxT",
  "animated": "cxz",
  "fadein-item": "cxa",
  "cell": "cxH",
  "cell-content": "cxO",
  "row-sidebar": "cxm",
  "empty": "cxp",
  "cell-amount": "cxx",
  "subtitle": "cxw caption",
  "hidden": "cxN",
  "cell-approve": "cxv",
  "quick-actions": "cxo",
  "account-select": "cxn",
  "note": "cxi",
  "cell-content-amount": "cxA body-1",
  "cell-status": "cxY"
};
