export default {
  "header-cell": "con",
  "empty": "coi",
  "header-content": "coA caption-bold",
  "active": "coY",
  "header-type": "coh",
  "header-reason": "cof",
  "header-status": "coK",
  "header-amount": "coG",
  "row-sidebar": "cor",
  "hidden": "cob",
  "animated": "coy",
  "fadein-item": "coU",
  "animated-cell": "coj"
};
