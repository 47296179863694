export default {
  "card": "cTM",
  "error": "cTP",
  "customer-info": "cTl",
  "edit-customer-tooltip": "cTX",
  "title": "cTC",
  "flex-row": "cTk",
  "dot-separator": "cTJ",
  "flex-span": "cTs",
  "faq-link": "cTF",
  "tooltip-wrapper": "cTg",
  "badge-union": "cTD"
};
