export default {
  "modal-wrapper": "cmq",
  "spinner-container": "cmZ",
  "spinner": "cmR",
  "container": "cmQ",
  "tabs-item": "cme",
  "form-container": "cmB",
  "fieldset": "cmE",
  "preview-container": "cmd",
  "switch-container": "cmI",
  "pdf-preview-container": "cmt",
  "form-content": "cmM",
  "button-container-footer": "cmP"
};
