export default {
  "wrapper": "crI",
  "beneficiary": "crt",
  "name": "crM body-1",
  "beneficiary-email": "crP body-2",
  "dropdown-icon": "crl",
  "trusted": "crX",
  "account-number": "crC flex body-2",
  "account-icon": "crk",
  "m-chip": "crJ",
  "dropdown": "crs body-2",
  "delete-option": "crF",
  "beneficiary-name": "crg"
};
