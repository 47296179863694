export default {
  "row": "cmb",
  "cell": "cmy",
  "greyed-out": "cmU",
  "struck-through": "cmj",
  "flex-cell": "cmW",
  "details-container": "cmu",
  "overdue": "cpS",
  "align-right": "cpc",
  "customer-name-container": "cpq",
  "icon": "cpZ",
  "warning": "cpR",
  "with-separator": "cpQ",
  "align-center": "cpe"
};
