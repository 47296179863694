export default {
  "row": "cGQ",
  "active": "cGe",
  "dot": "cGB",
  "red": "cGE",
  "green": "cGd",
  "yellow": "cGI",
  "status": "cGt",
  "align-right": "cGM",
  "animated": "cGP",
  "fadein-item": "cGl",
  "cell": "cGX body-2",
  "cell-content": "cGC",
  "amount": "cGk body-2",
  "subtitle": "cGJ caption",
  "padding-left": "cGs",
  "padding-right": "cGF",
  "note": "cGg"
};
