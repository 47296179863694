export default {
  "header": "cNW",
  "header-type": "cNu",
  "close": "cvS",
  "close-icon": "cvc",
  "header-infos": "cvq",
  "title": "cvZ body-1",
  "declined": "cvR",
  "canceled": "cvQ",
  "approved": "cve",
  "pending": "cvB",
  "date": "cvE body-2",
  "container": "cvd",
  "picto": "cvI",
  "picto-status": "cvt",
  "general": "cvM",
  "amount": "cvP",
  "counterparty": "cvl body-2",
  "initiator": "cvX",
  "initiator-avatar": "cvC",
  "avatar-image": "cvk",
  "initiator-id": "cvJ",
  "name": "cvs body-2",
  "role": "cvF caption-bold",
  "infobox": "cvg",
  "account-infobox": "cvD cvg",
  "beneficiary-warning": "cvV caption-bold",
  "dates-wrapper": "cvL",
  "dates": "cvT",
  "recurrence": "cvz caption-bold",
  "arrow": "cva",
  "notify-checkbox": "cvH"
};
