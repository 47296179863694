export default {
  "item": "cOG",
  "details": "cOr",
  "flex": "cOb",
  "header": "cOy cOb",
  "flex-no-gap": "cOU cOb",
  "file-name": "cOj",
  "label": "cOW body-2",
  "self-icon": "cOu",
  "with-separator": "cmS",
  "dimmed": "cmc"
};
