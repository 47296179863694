export default {
  "section-container": "cvA",
  "disclaimer-section": "cvY",
  "trip-title": "cvh caption-bold mb-16",
  "google-link-container": "cvf",
  "link-icon": "cvK",
  "trip-details": "cvG body-2",
  "trip-details-icon": "cvr",
  "trip-details-title": "cvb",
  "subtitle": "cvy caption-bold mb-16",
  "disclaimer": "cvU",
  "visualizer": "cvj"
};
