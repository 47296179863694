export default {
  "payment-details": "cal",
  "label-payment-conditions": "caX",
  "dropdown-content": "caC",
  "dropdown-option": "cak",
  "activate-sdd-container": "caJ",
  "activate-sdd-header": "cas",
  "selector-toggle-sdd": "caF",
  "selector-toggle-sdd-subtitle": "cag",
  "selector-toggle-content": "caD",
  "selector-toggle": "caV",
  "new-badge": "caL",
  "new-badge-activation": "caT",
  "mt-16": "caz",
  "sdd-mandate-select-loading": "caa"
};
