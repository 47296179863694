export default {
  "attachment-viewer": "cNN",
  "hide-sidebar": "cNv",
  "sidebar": "cNo",
  "header": "cNn",
  "header-top": "cNi",
  "header-icon": "cNA",
  "header-amount": "cNY",
  "infobox": "cNh",
  "vat": "cNf",
  "validation": "cNK"
};
