export default {
  "modal": "cOo",
  "action-container": "cOn",
  "similar-label": "cOi",
  "info-icon": "cOA",
  "container": "cOY",
  "subtitle": "cOh caption-bold",
  "error": "cOf",
  "item-container": "cOK"
};
